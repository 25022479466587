import Axios from 'axios';
import { getTokenForJobSeeker } from '../utils/helpers';
export const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_BASE_URL = `${BASE_URL}/api`;
function authRequestInterceptor(config){
    let accessToken = getTokenForJobSeeker();
    config.headers = config.headers ?? {};
    if (accessToken) {
        config.headers.authorization = `${accessToken}`;
      }
      config.headers.Accept = 'application/json';
      return config;
}
export const axios = Axios.create({
    baseURL: API_BASE_URL,
  });
axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (err)=>{
        if(err.response.status === 401){
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = '/';
            return false;
        }
        if(err.response.data.message !== undefined){
            // alert(err.response.data.message);
            return err.response.data;
        }else{
            alert(err.response.data.message);
            return false;
        }
    })