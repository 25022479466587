import { axios } from "../../lib/axios";

const getSubscriptionList= async(filter)=>{
    return axios.get('/employer/subscription-plan', { params: filter});
}
const getSubscriptionById= async(id)=>{
    return axios.get(`/employer/subscription-plan/${id}`);
}
const postSubscription= async (payload)=>{
    return axios.post('/employer/subscription-plan', payload);
}
const updateSubscription= async (id,payload)=>{
    return axios.put(`/employer/subscription-plan/${id}`, payload);
}

const getCurrentActivePlan= async ()=>{
    return axios.get(`/employer/active-subscription`);
}

const createOrder = async (id, body)=>{
    return axios.post(`/employer/subscription/${id}`, body);
}
const updateEmail = async (body)=>{
    return axios.patch(`/employer/employer-profile`, body);
}

export const Membership={
    getSubscriptionList,
    postSubscription,
    updateSubscription,
    getSubscriptionById,
    getCurrentActivePlan,
    createOrder,
    updateEmail
}