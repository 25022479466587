// export const BASE_URL = "https://arbeit.b2cinfohosting.in";
// export const BASE_URL = "https://api.arbeitnexus.com";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

const API_BASE_URL = `${BASE_URL}/api`;
    
export const JOB_SEEKER_TOKEN = localStorage.getItem('SEEKER_TOKEN')

export const GET_JOB_SEEKER_TOKEN =()=> { return localStorage.getItem('SEEKER_TOKEN')}
export const JOB_EMPLOYER_TOKEN = localStorage.getItem('EMPLOYER_TOKEN')

const endpoints = {
    // master
    getCompnay: `${API_BASE_URL}/admin/common/company`,
    getCountry: `${API_BASE_URL}/admin/common/country`,
    getState: `${API_BASE_URL}/admin/common/state`,
    getCity: `${API_BASE_URL}/admin/common/city`,
    getIndustryType: `${API_BASE_URL}/admin/common/industry`,
    webdepartmentType: `${API_BASE_URL}/web/department`,
    websubdept: `${API_BASE_URL}/web/sub-department`,

    getStateOpen: `${API_BASE_URL}/web/common/state`,
    getCityOpen: `${API_BASE_URL}/web/common/city`,


    // job seeker
    sendOtp: `${API_BASE_URL}/jobSeeker/mobile/send-otp`,
    verifyOtp: `${API_BASE_URL}/jobSeeker/mobile/verify-otp`,
    aadharSendOtp: `${API_BASE_URL}/jobSeeker/aadhar/send-otp`,
    aadharVerifyOtp: `${API_BASE_URL}/jobSeeker/aadhar/verify-otp`,
    seekerLogout: `${API_BASE_URL}/jobSeeker/logout`,
    getProfile: `${API_BASE_URL}/jobSeeker/profile`,
    updateSummery: `${API_BASE_URL}/jobSeeker/profile/summary`,
    getSkills: `${API_BASE_URL}/jobSeeker/profile/skills`,
    updateSkill: `${API_BASE_URL}/jobSeeker/profile/skills`,
    updatePersonalDetails: `${API_BASE_URL}/jobSeeker/profile/professional-profile-sample`,
    updateResume: `${API_BASE_URL}/jobSeeker/profile/resume`,
    getLanguage: `${API_BASE_URL}/jobSeeker/language`,
    updateLanguage: `${API_BASE_URL}/jobSeeker/profile/language`,
    getIndustry: `${API_BASE_URL}/jobSeeker/profile/industry`,
    getRoleCategory: `${API_BASE_URL}/jobSeeker/profile/roleCategory`,
    getRoles: `${API_BASE_URL}/jobSeeker/profile/role`,
    addEmploymentDetails: `${API_BASE_URL}/jobSeeker/profile/employment-details`,
    updateEmploymentDetails: `${API_BASE_URL}/jobSeeker/profile/employment-details`,
    updateWorkSample: `${API_BASE_URL}/jobSeeker/profile/work-sample`,
    getDepartment: `${API_BASE_URL}/jobSeeker/profile/department`,
    updateProfessionDetails: `${API_BASE_URL}/jobSeeker/profile/professional-details`,
    updateBasicDetails: `${API_BASE_URL}/jobSeeker/profile/basic-details`,
    updateCareerPreferences: `${API_BASE_URL}/jobSeeker/profile/career-preferences`,
    getIndustry: `${API_BASE_URL}/jobSeeker/profile/industry`,
    getProfilePercent: `${API_BASE_URL}/jobSeeker/profile/percentage`,
    myJobs: `${API_BASE_URL}/jobSeeker/myjob`,
    jobRating: `${API_BASE_URL}/jobSeeker/job/rating`,
    deleteResume: `${API_BASE_URL}/jobSeeker/delete_resume`,
    checkPlan: `${API_BASE_URL}/jobSeeker/plan-check`,
    handleFavorite: `${API_BASE_URL}/jobSeeker/job/favourite`,
    jobApply: `${API_BASE_URL}/jobSeeker/job/apply`,
    getRecentJobs: `${API_BASE_URL}/jobSeeker/new-hiring`,
    getFilterData: `${API_BASE_URL}/web/get-filter-data`,
    seekerChat: `${API_BASE_URL}/jobSeeker/getJobEmployeerListForChat`,
    seekerGetNotification: `${API_BASE_URL}/jobSeeker/notification-list`,
	languageDelete: `${API_BASE_URL}/jobSeeker/profile/languagedel`,


    // employer
    empSendOtp: `${API_BASE_URL}/employer/mobile/send-otp`,
    empVerifyOtp: `${API_BASE_URL}/employer/mobile/verify-otp`,
    companyProfile: `${API_BASE_URL}/employer/company-profile`,
    verifyGST: `${API_BASE_URL}/employer/verify-gst-number`,
    empLogout: `${API_BASE_URL}/employer/logout`,
    dashboard: `${API_BASE_URL}/employer/dashboard`,
    empProfile: `${API_BASE_URL}/employer/company-profile`,
    createJob: `${API_BASE_URL}/employer/job/create`,
    filterData: `${API_BASE_URL}/employer/get-filter-data`,
    empGetNotification: `${API_BASE_URL}/employer/notification-list`,
    jobdetails: `${API_BASE_URL}/employer/job`,
    jobdelete: `${API_BASE_URL}/employer/job-delete`,
    empPlan: `${API_BASE_URL}/employer/active-subscription`,
    empreview: `${API_BASE_URL}/employer/review`,
    empsubscription: `${API_BASE_URL}/employer/membership-plan`,
    empChat: `${API_BASE_URL}/employer/index-job-seeker-list`,

    //Web
    getInfo: `${API_BASE_URL}/web/siteinfo`,
    contactUs: `${API_BASE_URL}/web/contact-us`,

    getIndustryTypeWeb: `${API_BASE_URL}/web/industry`,

    // UPLOAD
    uploadImage:`${API_BASE_URL}/admin/common/upload`

};

export default endpoints;