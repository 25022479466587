// src/components/Layout.js
import React, { useEffect, useRef } from 'react';
import Header from './Header';
import Footer from './Footer';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const Layout = ({ children }) => {
  const headerRef = useRef(null); // Reference for the Header component
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');

  useEffect(() => {
    // Function to handle click events
    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        // If clicked outside of header, remove 'show' class from any dropdowns
        const dropdowns = document.querySelectorAll('.login-drop.dropdown.show');
        dropdowns.forEach((dropdown) => {
          dropdown.classList.remove('show');
        });

        const dropdownMenus = document.querySelectorAll('.dropdown-menu.show');
        dropdownMenus.forEach((menu) => {
          menu.classList.remove('show');
        });
      }
    };

    // Add event listener for clicks
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <div>
        <ToastContainer />
        {!token && <div ref={headerRef}>
         <Header />
      </div>}
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
