import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiCallFetch from '../utils/apiHelperFetch';
import { toast } from 'react-toastify';
import endpoints, { BASE_URL } from '../utils/apiEndpoints';
import apiCall from '../utils/apiHelper';
import { getGooglePlayStore } from '../utils/constants';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [info, setInfo] = useState({});
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  useEffect(() => {
    getInfo()
  }, [])
  
  const getInfo = async () => {
    try {
      const response = await apiCall(`${endpoints.getInfo}`, 'GET', null, {});
      
      if (!response.status && response.message === 'No result found') {
        setInfo(response.data);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
      setInfo(error.response.data.data);
    }
  };
  const handleSubscribe = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    setError(''); 
    try {
        const result = await apiCallFetch( `${BASE_URL}/api/web/newsletter`, 'POST', { email });
        //console.log(result,"subscribe");
        if(result.status){
            toast.success(result.message, {
                position: "top-right",
                autoClose: 3000,
            });
            setEmail('')
        }else{
            toast.error(result.message, {
                position: "top-right",
                autoClose: 3000,
            });
        }
     
      } catch (error) {
        console.error('Subscription failed:', error);
      }
  };

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 fotr-box">
            <div className="fotr-media-item adress">
              <div className="fotr-logo">
                <img src="/images/logo.svg" alt="Logo" />
              </div>
              <p>Empowering professionals with innovative, tailored solutions for growth and success in the retail and hospitality industries.</p>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
            <div className="row">
              <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                <div className="fotr-media-item">
                  <h3>Quick Links</h3>
                  <ul className="list-unstyled fotr-menu">
                    <li><a href="/about-us">About Us</a></li>
                    <li><a href="/employer/login">Employer login</a></li>
                    <li><a href="/joblisting">Jobs</a></li>
                    <li><a href="/companies">Companies</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-6 col-sm-3 col-md-2 col-lg-2">
                <div className="fotr-media-item">
                  <h3>Company</h3>
                  <ul className="list-unstyled fotr-menu">
                    <li><a href="/contactus">Contact us</a></li>
                    <li><a href="/blogs">Blogs</a></li>
                    <li><a href="/employer/login">Login</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                <div className="fotr-media-item">
                  <h3>Connect with us on</h3>
                  <div className="foter-search">
                    <form className="navbar-form" onSubmit={handleSubscribe}>
                      <div className="form-group">
                        <label>Subscribe to Newsletter</label>
                        <div className="d-flex">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email ID"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <button type="submit" className="btn btn-primary ms-1">Subscribe</button>
                        </div>
                        {error && <div style={{ color: 'red', marginTop: '5px',fontSize:'12px' }}>{error}</div>}
                      </div>
                    </form>
                  </div>
                  <h4>Social Media</h4>
                  <ul className="list-inline hdr-social-link d-flex mb-0">
                    <li><a href={info?.fb_link} target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a></li>
                    <li><a href={info?.twitter_link} target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a></li>
                    <li><a href={info?.linkedin_link} target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a></li>
                    <li><a href={info?.thread_link} target="_blank" rel="noopener noreferrer"><img src="/images/threads-icon.png" alt="Threads" /></a></li>
                    <li><a href={info?.insta_link} target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                <div className="fotr-media-item">
                  <h3>Download the app</h3>
                  <ul className="app-cate-list list-unstyled d-flex flex-wrap">
                    <li><a href={getGooglePlayStore()?.google} target="_blank"><img src="/images/google-playstore.png" alt="Google Playstore" /></a></li>
                    <li><a href="#"><img src="/images/appstore-black.png" alt="App Store" /></a></li>
                  </ul>
                  <div className="scan-qr"><img src={getGooglePlayStore()?.barCode} alt="QR Code" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="cpyrgt-wrap">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8">
              <p><Link to="privacy-policy">Privacy Policy</Link> &nbsp; | &nbsp; <Link to="/terms-and-conditions">Terms & Conditions</Link> &nbsp; | &nbsp; <Link to="/cookies_policy">Cookies Policy</Link></p></div>
            <div className="col-12 col-sm-12 col-md-4 txt-rgt">
              <p>© Arbeit All rights reserved</p>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
