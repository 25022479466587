import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import moment from "moment/moment";
import clsx from 'clsx';
import { Membership } from "../services/jobseeker/subscription";

const PopupSidebar = ({ show, handleClose }) => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("EMP_TOKEN"); // Retrieve token from localStorage

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const result = await Membership.getCurrentActivePlan();
        setPlans(result.data);
      } catch (error) {
        console.error("Failed to fetch plans:", error);
        toast.error(error.response, {
          position: "top-right",
          autoClose: 3000,
        });
        if (
          error?.response?.status === 422 ||
          error?.response?.status === 401
        ) {
          window.location.href = "/";
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, [navigate, token]);

  const createListItems = (description) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, "text/html");

    // Check if there are list items in the description
    const listItems = doc.querySelectorAll("li");
    if (listItems.length > 0) {
      return Array.from(listItems).map((item, index) => (
        <li
          key={index}
          style={{
            listStyleType: "none", // Remove bullet points
            paddingLeft: "10px", // Adjust padding if needed
            margin: "0", // Remove any margin
          }}
        >
          {item.textContent}
        </li>
      ));
    } else {
      return <div>Plan</div>;
    }
  };

  return (
    <>
      {/* Blur Overlay */}
      {show && <div className="blur-overlay" onClick={handleClose}></div>}
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="my-plan-title">My Plan</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              alignContent: "center",
              alignSelf: "center",
            }}
          >
            {loading ? (
              <div className="col-12">
                <div className="text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : 
            plans?.planDetails?.name 
            ? (
              <div className="">
                <div className="d-flex flex-column align-content-center align-items-center section-my-plan-sidebar">
                <div
  className={clsx(
    {
      'free-box-my-plan': plans?.planDetails?.type === 'free' || !plans?.planDetails?.type,
      'basic-box-theme': plans?.planDetails?.type === 'basic',
      'premium-box-theme': plans?.planDetails?.type === 'premium',
    },
    'basic-box-theme',
    'd-flex',
    'justify-content-center'
  )}
>

                            {(() => {
                    switch (plans?.planDetails?.type) {
                      case "basic":
                        return (
                          <div className="">
                            <Icon icon="system-uicons:diamond" width={30} />
                          </div>
                        );
                      case "premium":
                        return (
                          <div className="">
                            <Icon icon="octicon:goal-24" width={30} color="F7AD14"/>
                          </div>
                        );
                      case "free":
                        return (
                          <div >
                            <Icon icon="ph:crown-simple" color="#ffff" width={30} />
                          </div>
                        );
                      default:
                        return (
                          <div >
                            <Icon icon="ph:crown-simple" color="#ffff" width={30} />
                          </div>
                        );
                    }
                  })()}

                    {/* <Icon icon="ph:crown-simple" color="#ffff" width={60} /> */}
                  </div>
                  <div className="text-center">
                    <div className="d-flex flex-column align-items-center mt-5 gap-3">
                      <div>Current Plan</div>
                      <div className="planType-title">
                        {plans?.planDetails?.name}
                      </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                      <div className="d-flex align-items-center">
                        <Icon icon="ic:round-currency-rupee" width={24} />{" "}
                        <span className="my-plan-price">
                          {plans?.planDetails?.price ?? 0}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="plan-end-date-section mt-2">
                    <Icon icon="ph:info-fill" width={24} color="#8E8E8E" />{" "}
                    <div className="jobseeker-plan-type">
                      Your {plans?.planDetails?.type.charAt(0).toUpperCase()
                        + plans?.planDetails?.type.slice(1)} Plan will renew on{" "}
                      {moment(plans?.endDate).format("D MMMM, YYYY")}
                    </div>
                    <div style={{
                      fontSize:'12px'
                    }}>
                    After that You will be billed <Icon icon="ic:round-currency-rupee" width={14} />{plans?.planDetails?.price ?? 0}
                    </div>
                  </div>

                  <button className={`buyNow-btn mt-5 w-80`} onClick={() => window.location.href='/subscriptions'}>
                    Change Plan
                  </button>
                </div>
              </div>
            ) : (
              <div className="col-12 text-center">
                <h5>No Plan Found</h5>
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/subscriptions")}
                >
                  Subscribe Now
                </button>
              </div>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default PopupSidebar;
